import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <TopBanner className="lightBg textCenter">
          <h1 className="font30 extraBold" style={{paddingTop: "85px"}}>We adopt a value-based approach to software sales, <br />
           ensuring that businesses of all sizes can <br />harness the benefit of our solution</h1>
        </TopBanner>
        <div className="container">
          <HeaderInfo className="textCenter" style={{paddingTop: "30px", paddingBottom: "30px"}}>
            <h1 className="font40 extraBold">Our subcription plans are flexible and affordable</h1>
            <p className="font13 semiBold">
              Choose the right plan for your business.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                title="Trial"
                text="WHAT YOU GET"
                offers={[
                  { name: "Unlimited user", cheked: true },
                  { name: "Unlimited workflow", cheked: true },
                  { name: "Shared cloud", cheked: true },
                  { name: "Unlimited Tasks and project", cheked: true },
                  { name: "Activity log", cheked: true },
                  { name: "Email notification", cheked: true },
                  { name: "Trial period is 30 days", cheked: true },
                  { name: "Activity log", cheked: true },
                  { name: "24/7 customer care representative and reporting analytics", cheked: true },
                ]}
              />
            </TableBox>
         
            <TableBox>
              <PricingTable
                title="Exclusive"
                text="WHAT YOU GET"
                offers={[
                  { name: "Unlimited users access", cheked: true },
                  { name: "Unlimited Workflow", cheked: true },
                  { name: "Shared cloud", cheked: true },
                  { name: "Unlimited Tasks and project", cheked: true },
                  { name: "Activity log", cheked: true },
                  { name: "Email notification", cheked: true },
                  { name: "Unlimited real time notification", cheked: true },
                  { name: "24/7 customer care representative and reporting analytics", cheked: true },
                ]}
              />
            </TableBox>

            <TableBox>
              <PricingTable
                title="Enterprise"
                text="WHAT YOU GET"
                offers={[
                  { name: "Unlimited users access", cheked: true },
                  { name: "Unlimited workflow", cheked: true },
                  { name: "Private cloud", cheked: true },
                  { name: "Unlimited Tasks and project", cheked: true },
                  { name: "Activity log", cheked: true },
                  { name: "Email notification", cheked: true },
                  { name: "Unlimited real time notification", cheked: true },
                  { name: "24/7 dedicated customer care representative and reporting anaylitcs", cheked: true },
                ]}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
    
  );
}

const TopBanner = styled.div`
  width: 100%;
  min-height: 300px;
`;
const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  Padding-top: 80px
`;
const HeaderInfo = styled.div`
  margin-bottom: 2px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
   
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
    
  }
`;




