import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { Link } from "react-scroll";
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import ContactImg00 from "../../assets/img/app-l.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import FullButton from "../Buttons/FullButton";
//screens
import Home from "../../screens/Home";
import Company from "../../screens/Company";
import Resources from "../../screens/Resources";
import Solution from "../../screens/Solution";
import Pricings from "../../screens/Pricing";
import Contacts from "../../screens/Contact";


export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <BrowserRouter>
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
        <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
          <NavInner className="container flexSpaceCenter">
            <Link className="pointer flexNullCenter" to="/" smooth={true}>
              <img src={ContactImg00} alt="office" className="radius6" />
              <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                Flexcoord
              </h1>
            </Link>
            <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
              <BurgerIcon />
            </BurderWrapper>
            <UlWrapper className="flexNullCenter">
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="/solution"  offset={-80}>
                  Solution
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="/company"  offset={-80}>
                  Company
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="/resources"  offset={-80}>
                  Resources
                </Link>
              </li>
              <li className="semiBold font15 pointer">
                <Link activeClass="active" style={{ padding: "10px 15px" }} to="/pricing"  offset={-80}>
                  Pricing
                </Link>
              </li>
            </UlWrapper>
            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font15 pointer" style={{ width: "190px" }}>
                <Link to="/contact">
                  <FullButton title="Contact Us" />
                </Link>
              </li>
            </UlWrapperRight>
          </NavInner>
        </Wrapper>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/solution" element={<Solution />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/resources" element={<Resources />} />
          <Route exact path="/pricing" element={<Pricings />} />
          <Route exact path="/contact" element={<Contacts />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


