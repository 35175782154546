import React from "react";
import styled from "styled-components";
// Components
import { Link } from "react-router-dom";
import FullButton from "../Buttons/FullButton";
import CheckMark from "../../assets/svg/Checkmark";

export default function PricingTable({ icon, price, title, text,  offers, action }) {
  return (
    <Wrapper className="radius8 shadow lightBg">
      <div style={{ margin: "30px 0"}}>
        <h3 className="font30 extraBold textCenter" >{title}</h3>
      </div>
      <p className="font13 extraBold">{text}</p>
      <div>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", marginRight: "15px" }}>
                  {item.cheked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font14 ">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>
        <Link to="/contact">
          <FullButton title="Contact Us" action={action} />
        </Link>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
  color:"#F59B24"
`;
