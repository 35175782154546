import React from "react";
// Sections
import Footer from "../components/Sections/Footer";
import Companys from "../components/Sections/Company/Company";

export default function Company() {
  return (
    <>
      <Companys />
      <Footer />
    </>
  );
}