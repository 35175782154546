import React from "react";
import styled from "styled-components";
// Components
import TextTruncate from 'react-text-truncate';
import Post1 from "../../../assets/img/post-1.png";
import Post2 from "../../../assets/img/post-2.jpg";
import Post3 from "../../../assets/img/post-3.jpg";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <HeaderWrapper>
              <h1 className="font20 extraBold">Blog</h1>
            </HeaderWrapper>
            
            <div className="lightBg">
              <NewsBanner>
                <ImageWrapper>
                  <img src={Post1} alt="post" />
                </ImageWrapper>
                <div>
                  <h1 className="font30 semiBold">Utilizing digitalization and workflow automation for business transformation</h1>
                  <div style={{marginTop: "10px"}}> 
                    <Badge>Digitalization and automation</Badge> 
                  </div>
                  <div style={{marginTop: "10px"}}>
                    <TextTruncate
                      line={3}
                      element="span"
                      truncateText="…"
                      text="In today's rapidly evolving business landscape, leveraging workflow automation, project management, task management, and electronic document management
                       systems is paramount for organizations striving to remain competitive and agile. Workflow automation empowers businesses to streamline their operational processes 
                       by automating routine tasks and workflows, thus reducing manual intervention, minimizing errors, and increasing operational efficiency. By automating 
                       repetitive tasks such as data entry, approvals, and notifications, organizations can optimize resource utilization and accelerate time-to-market, fostering 
                       innovation and growth. 

                       Project management tools serve as indispensable assets for organizations undertaking complex initiatives, enabling effective planning, execution, and monitoring of projects 
                       from inception to completion. These platforms offer a myriad of features, including task allocation, progress tracking, budget management, and resource optimization, facilitating 
                       seamless collaboration among team members and stakeholders. With real-time visibility into project timelines, milestones, and performance metrics, project management tools empower 
                       businesses to proactively identify and mitigate risks, ensuring successful project outcomes and stakeholder satisfaction.

                       Task management systems provide individuals and teams with robust frameworks for organizing, prioritizing, and tracking tasks efficiently. These platforms facilitate clear 
                       communication, accountability, and transparency, empowering teams to stay focused, meet deadlines, and deliver high-quality results. By centralizing task assignments, deadlines, 
                       and progress updates, task management systems enhance collaboration and alignment across teams, driving productivity and fostering a culture of continuous improvement.

                       Electronic document management systems (EDMS) revolutionize the way organizations manage, store, and access their critical documents and files. By digitizing document 
                       processes and centralizing document storage, EDMS platforms enable seamless document sharing, version control, and collaboration among employees, partners, and customers. With 
                       advanced search capabilities, security features, and compliance controls, EDMS platforms empower organizations to streamline document workflows, mitigate risks, and ensure 
                       regulatory compliance, thereby enhancing operational efficiency and data security.

                       In essence, the integration of workflow automation, project management, task management, and electronic document management systems enables organizations to optimize their 
                       processes, empower their teams, and drive innovation and growth. By embracing these technologies, businesses can unlock new levels of efficiency, agility, and competitiveness, 
                       positioning themselves for success in today's dynamic business environment."
                      textTruncateChild={<a href="https://medium.com/@admin_49/utilizing-digitalization-and-workflow-automation-for-business-transformation-18dfe29a21c3" target="_blank">Read on</a>}
                    />
                  </div>
                </div>
              </NewsBanner>
            </div>
          </HeaderInfo>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-6 col-lg-6">
              <div className="lightBg">
                <NewsBanner>
                  <ImageWrapper>
                    <img src={Post2} alt="post" />
                  </ImageWrapper>
                  <div>
                    <h1 className="font30 semiBold">Unlocking Success: The Power of Effective Project Management</h1>
                    <div style={{marginTop: "10px"}}> 
                      <Badge>Project management</Badge> 
                    </div>
                    <div style={{marginTop: "10px"}}>
                      <TextTruncate
                        line={3}
                        element="span"
                        truncateText="…"
                        text="In today's fast-paced and competitive business environment, effective project management stands as a cornerstone of success for organizations 
                         across industries. As businesses strive to innovate, adapt to changing market dynamics, and deliver value to their customers, the ability to plan, 
                         execute, and monitor projects efficiently has become paramount. 'Unlocking Success: The Power of Effective Project Management' delves into the critical 
                         role that robust project management practices play in driving organizational success.
                        
                         At its core, effective project management encompasses a systematic approach to initiating, planning, executing, monitoring, and closing projects. It involves 
                         defining project objectives, identifying key stakeholders, allocating resources, establishing timelines, and managing risks and constraints. By employing proven 
                         methodologies such as Agile, Waterfall, or hybrid approaches, organizations can tailor their project management processes to suit the unique requirements of each 
                         initiative.
                        
                         One of the primary benefits of effective project management is its ability to enhance productivity and efficiency. By breaking down complex projects into manageable 
                         tasks, setting clear objectives, and establishing accountability mechanisms, project managers empower teams to work cohesively towards shared goals. Moreover, by 
                         leveraging project management tools and software platforms, teams can streamline communication, track progress in real-time, and proactively address issues as they arise, 
                         ensuring projects stay on track and within budget.
                        
                         Furthermore, effective project management fosters collaboration and alignment across diverse teams and departments within an organization. By promoting open communication, 
                         encouraging knowledge sharing, and fostering a culture of transparency, project managers create an environment where stakeholders feel empowered to contribute their expertise 
                         and insights. This collaborative approach not only accelerates decision-making but also drives innovation and continuous improvement, enabling organizations to stay ahead of 
                         the curve in today's rapidly evolving business landscape.
                        
                         In addition to driving operational efficiency and collaboration, effective project management also plays a pivotal role in mitigating risks and maximizing opportunities. 
                         By conducting thorough risk assessments, developing contingency plans, and monitoring key performance indicators (KPIs), project managers can identify potential obstacles 
                         early on and take proactive measures to mitigate their impact. Moreover, by leveraging data analytics and insights, organizations can optimize resource allocation, identify 
                         areas for improvement, and capitalize on emerging trends, thereby increasing their competitive advantage in the marketplace.
                        
                         In conclusion, 'Unlocking Success: The Power of Effective Project Management' highlights the transformative impact that robust project management practices can have on 
                         organizational success. By adopting a strategic and disciplined approach to project management, organizations can optimize their operations, drive innovation, and achieve 
                         their strategic objectives with confidence. In an era defined by complexity, uncertainty, and rapid change, effective project management serves as a guiding light, empowering 
                         organizations to navigate challenges, capitalize on opportunities, and unlock their full potential."
                        textTruncateChild={<a href="https://medium.com/@admin_49/unlocking-success-the-power-of-effective-project-management-ebb2b340e4f4" target="_blank">Read on</a>}
                      />
                    </div>
                  </div>
                </NewsBanner>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="lightBg">
                <NewsBanner>
                  <ImageWrapper>
                    <img src={Post3} alt="post" />
                  </ImageWrapper>
                  <div>
                    <h1 className="font30 semiBold">Mastering Task Management: Strategies for Enhanced Productivity and Success</h1>
                    <div style={{marginTop: "10px"}}> 
                      <Badge>Task management</Badge> 
                    </div>
                    <div style={{marginTop: "10px"}}>
                      <TextTruncate
                        line={3}
                        element="span"
                        truncateText="…"
                        text="In today's fast-paced world, effective task management is the cornerstone of productivity and success. From managing daily to-do lists to 
                         overseeing complex projects, mastering task management is essential for individuals and teams striving to achieve their goals efficiently. In this 
                         article, we will explore key strategies and techniques for mastering task management and enhancing productivity and success in both personal and 
                         professional endeavors.

                         Effective task management is critical for maintaining focus, staying organized, and achieving goals. Without a structured approach to managing tasks, 
                         individuals risk becoming overwhelmed by competing priorities, missing deadlines, and feeling unproductive. By mastering task management, individuals 
                         can regain control of their time, increase productivity, and achieve greater success in their endeavors.
                        
                         Prioritization is fundamental to task management. Effective prioritization involves identifying and focusing on tasks that have the greatest impact on 
                         achieving goals. Techniques such as the Eisenhower Matrix, ABC prioritization, and the Pomodoro Technique can help individuals allocate their time and 
                         energy more effectively, ensuring that high-priority tasks receive the attention they deserve.
                        
                         In addition to prioritization, effective time management is essential for optimizing productivity. Time management strategies such as setting SMART goals, 
                         creating daily schedules, and minimizing distractions can help individuals make the most of their time and accomplish tasks more efficiently. By managing time 
                         wisely and avoiding common time-wasting activities, individuals can increase their productivity and achieve their goals with greater ease.
                        
                         Streamlining workflows is another key aspect of mastering task management. By identifying inefficiencies and bottlenecks in workflows, individuals can implement process 
                         improvements to optimize productivity and reduce wasted time and resources. Techniques such as workflow mapping, process automation, and continuous improvement can help 
                         individuals streamline their workflows and achieve better results in less time.
                        
                         Effective collaboration and communication are essential for successful task management, especially in team settings. Clear communication, regular check-ins, and 
                         collaborative tools such as project management software can help teams stay aligned, share information, and coordinate tasks more effectively. By fostering a culture 
                         of collaboration and open communication, teams can achieve their goals more efficiently and deliver better results.
                        
                         Task management tools play a crucial role in mastering task management. From simple to-do list apps to sophisticated project management platforms, there is a wide range 
                         of tools available to help individuals and teams organize tasks, track progress, and collaborate more effectively. By leveraging the right tools for their needs, individuals 
                         can streamline their task management processes and achieve greater success in their endeavors.
                        
                         Conclusion: Mastering task management is a journey that requires dedication, discipline, and continuous improvement. By implementing prioritization techniques, time management 
                         strategies, workflow optimization, effective collaboration, and utilizing task management tools, individuals and teams can enhance their productivity, achieve their goals, and 
                         ultimately, succeed in their personal and professional endeavors. With the right strategies and techniques in place, mastering task management becomes not only achievable but 
                         also transformative, leading to enhanced productivity and success in all aspects of life."
                        textTruncateChild={<a href="https://medium.com/@admin_49/mastering-task-management-strategies-for-enhanced-productivity-and-success-3ca0ac9f4c7c" target="_blank">Read on</a>}
                      />
                    </div>
                  </div>
                </NewsBanner>
              </div>
            </div>
            {/* <div className="col-xs-12 col-sm-4 col-md-6 col-lg-6">
              <div className="lightBg">
                <NewsBanner>
                  <ImageWrapper>
                    <img src={Post1} alt="post" />
                  </ImageWrapper>
                  <div>
                    <h1 className="font30 semiBold">Utilizing digitalization and workflow automation for business transformation.</h1>
                    <div style={{marginTop: "10px"}}> 
                      <Badge>Digitalization and automation</Badge> 
                    </div>
                    <div style={{marginTop: "10px"}}>
                      <TextTruncate
                        line={3}
                        element="span"
                        truncateText="…"
                        text="In today's rapidly evolving business landscape, leveraging workflow automation, project management, task management, and electronic document management
                        systems is paramount for organizations striving to remain competitive and agile. Workflow automation empowers businesses to streamline their operational processes 
                        by automating routine tasks and workflows, thus reducing manual intervention, minimizing errors, and increasing operational efficiency. By automating 
                        repetitive tasks such as data entry, approvals, and notifications, organizations can optimize resource utilization and accelerate time-to-market, fostering 
                        innovation and growth. 

                        Project management tools serve as indispensable assets for organizations undertaking complex initiatives, enabling effective planning, execution, and monitoring of projects 
                        from inception to completion. These platforms offer a myriad of features, including task allocation, progress tracking, budget management, and resource optimization, facilitating 
                        seamless collaboration among team members and stakeholders. With real-time visibility into project timelines, milestones, and performance metrics, project management tools empower 
                        businesses to proactively identify and mitigate risks, ensuring successful project outcomes and stakeholder satisfaction.

                        Task management systems provide individuals and teams with robust frameworks for organizing, prioritizing, and tracking tasks efficiently. These platforms facilitate clear 
                        communication, accountability, and transparency, empowering teams to stay focused, meet deadlines, and deliver high-quality results. By centralizing task assignments, deadlines, 
                        and progress updates, task management systems enhance collaboration and alignment across teams, driving productivity and fostering a culture of continuous improvement.

                        Electronic document management systems (EDMS) revolutionize the way organizations manage, store, and access their critical documents and files. By digitizing document 
                        processes and centralizing document storage, EDMS platforms enable seamless document sharing, version control, and collaboration among employees, partners, and customers. With 
                        advanced search capabilities, security features, and compliance controls, EDMS platforms empower organizations to streamline document workflows, mitigate risks, and ensure 
                        regulatory compliance, thereby enhancing operational efficiency and data security.

                        In essence, the integration of workflow automation, project management, task management, and electronic document management systems enables organizations to optimize their 
                        processes, empower their teams, and drive innovation and growth. By embracing these technologies, businesses can unlock new levels of efficiency, agility, and competitiveness, 
                        positioning themselves for success in today's dynamic business environment."
                        textTruncateChild={<a href="#">Read on</a>}
                      />
                    </div>
                  </div>
                </NewsBanner>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="lightBg">
                <NewsBanner>
                  <ImageWrapper>
                    <img src={Post1} alt="post" />
                  </ImageWrapper>
                  <div>
                    <h1 className="font30 semiBold">Utilizing digitalization and workflow automation for business transformation.</h1>
                    <div style={{marginTop: "10px"}}> 
                      <Badge>Digitalization and automation</Badge> 
                    </div>
                    <div style={{marginTop: "10px"}}>
                      <TextTruncate
                        line={3}
                        element="span"
                        truncateText="…"
                        text="In today's rapidly evolving business landscape, leveraging workflow automation, project management, task management, and electronic document management
                        systems is paramount for organizations striving to remain competitive and agile. Workflow automation empowers businesses to streamline their operational processes 
                        by automating routine tasks and workflows, thus reducing manual intervention, minimizing errors, and increasing operational efficiency. By automating 
                        repetitive tasks such as data entry, approvals, and notifications, organizations can optimize resource utilization and accelerate time-to-market, fostering 
                        innovation and growth. 

                        Project management tools serve as indispensable assets for organizations undertaking complex initiatives, enabling effective planning, execution, and monitoring of projects 
                        from inception to completion. These platforms offer a myriad of features, including task allocation, progress tracking, budget management, and resource optimization, facilitating 
                        seamless collaboration among team members and stakeholders. With real-time visibility into project timelines, milestones, and performance metrics, project management tools empower 
                        businesses to proactively identify and mitigate risks, ensuring successful project outcomes and stakeholder satisfaction.

                        Task management systems provide individuals and teams with robust frameworks for organizing, prioritizing, and tracking tasks efficiently. These platforms facilitate clear 
                        communication, accountability, and transparency, empowering teams to stay focused, meet deadlines, and deliver high-quality results. By centralizing task assignments, deadlines, 
                        and progress updates, task management systems enhance collaboration and alignment across teams, driving productivity and fostering a culture of continuous improvement.

                        Electronic document management systems (EDMS) revolutionize the way organizations manage, store, and access their critical documents and files. By digitizing document 
                        processes and centralizing document storage, EDMS platforms enable seamless document sharing, version control, and collaboration among employees, partners, and customers. With 
                        advanced search capabilities, security features, and compliance controls, EDMS platforms empower organizations to streamline document workflows, mitigate risks, and ensure 
                        regulatory compliance, thereby enhancing operational efficiency and data security.

                        In essence, the integration of workflow automation, project management, task management, and electronic document management systems enables organizations to optimize their 
                        processes, empower their teams, and drive innovation and growth. By embracing these technologies, businesses can unlock new levels of efficiency, agility, and competitiveness, 
                        positioning themselves for success in today's dynamic business environment."
                        textTruncateChild={<a href="#">Read on</a>}
                      />
                    </div>
                  </div>
                </NewsBanner>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 80px;
  padding-bottom: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const NewsBanner = styled.div`
  width: 100%;
  border-radius: 10px;
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;
  background: brown;
  border-radius: 10px;
`;
const Badge = styled.span`
  padding: 10px;
  border-radius: 10px;
  background: #F0EBE3;
`;
const Truncate = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
`;
const HeaderWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`