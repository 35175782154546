import React from "react";
import Slider from "react-slick";
// Assets
import ClientLogo01 from "../../assets/img/partner-1.png"

export default function ClientSlider() {
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
  };
  return (
    <div>
      <Slider {...settings}>
        <div className="flexCenter">
          <img src={ClientLogo01} alt="client logo" className="flexCenter"/>
        </div>
      </Slider>
    </div>
  );
}