import React from "react";
import styled from "styled-components";
// Assets
import Solution1 from "../../assets/img/solutions/solution-1.png";
import Solution2 from "../../assets/img/solutions/solution-2.png";
import Solution3 from "../../assets/img/solutions/solution-3.png";
import Solution4 from "../../assets/img/solutions/solution-4.png";

export default function ServiceBox({icon, title, subtitle, action}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <img className="radius8" src={Solution1} alt="solutions" width={100} height={100}></img>;
      break;
    case "monitor":
      getIcon = <img className="radius8" src={Solution2} alt="solutions" width={100} height={100}></img>;
      break;
    case "browser":
      getIcon = <img className="radius8" src={Solution3} alt="solutions" width={100} height={100}></img>;
      break;
    case "printer":
      getIcon = <img className="radius8" src={Solution4} alt="solutions" width={100} height={100}></img>;
      break;
    default:
      getIcon = <img className="radius8" src={Solution1} alt="solutions" width={100} height={100}></img>;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}>
        {getIcon}
      </ImgBtn>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
  `;