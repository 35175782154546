import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../../Buttons/FullButton";
// Assets
import Sol1 from "../../../assets/img/sol-1.jpg";
import Sol2 from "../../../assets/img/sol-2.jpg";
import Sol3 from "../../../assets/img/sol-3.jpg";
import Sol4 from "../../../assets/img/sol-4.jpg";
import Dots from "../../../assets/svg/Dots";

export default function solutions() {
  return (
    <>
      <WrapperLegend id="home" className="container">
        <HeaderInfo className="textCenter">
          <h1 className="font30 extraBold">
            Our awesome solution revolutionizes your business with seamless project <br />
            management, efficient task monitoring, advanced workflow <br />
            automation and a centralized document storage.
          </h1>
        </HeaderInfo>
      </WrapperLegend>

      <Legend className="lightBg">
        <Wrapper id="home" className="container flexSpaceCenter">
          <LeftSide className="flexCenter">
            <div>
              <h1 className="extraBold font40">Project Management System (PMS)</h1>
              <HeaderP className="font13 semiBold" style={{textAlign: "justify"}}>
                Flexcoord project management system is a comprehensive tool that helps individuals, teams, and organizations efficiently 
                organize and execute projects from start to finish. It offers key features such as task and resource management, scheduling 
                and timeline tracking, collaboration and communication tools, centralized document storage, and performance analytics. 
                These features streamline workflows, enhance productivity, improve transparency, and enable better control over project costs and resources. .
              </HeaderP>
            </div>
          </LeftSide>
          <RightSide>
            <ImageWrapper>
              <Img className="radius8" src={Sol1} alt="office" style={{zIndex: 9}} />
              <DotsWrapper>
                <Dots />
              </DotsWrapper>
            </ImageWrapper>
          </RightSide>
        </Wrapper>
      </Legend>

      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide>
          <ImageWrapperLegend>
            <Img className="radius8" src={Sol2} alt="office" style={{zIndex: 9}} />
            <DotsWrapperLegend>
              <Dots />
            </DotsWrapperLegend>
          </ImageWrapperLegend>
        </LeftSide>
        <RightSide className="flexCenter">
          <div>
            <h1 className="extraBold font40">Task Management System (TMS)</h1>
            <HeaderP className="font13 semiBold" style={{textAlign: "justify"}}>
              Flexcoord task management system is a comprehensive tool that helps individuals, teams, and organizations efficiently plan, organize, 
              and track tasks. Key features include task planning and prioritization, resource allocation, real-time progress tracking, collaboration tools, 
              notifications, and reminders. The benefits include enhanced productivity, improved time management, better collaboration, increased accountability, 
              and streamlined workflows. By centralizing task management and providing visibility into progress, it supports successful project outcomes and boosts organizational performance.
            </HeaderP>
          </div>
        </RightSide>
      </Wrapper>

      <Legend className="lightBg">
        <Wrapper id="home" className="container flexSpaceCenter">
          <LeftSide className="flexCenter">
            <div>
              <h1 className="extraBold font40">Workflow Automation System (WAS)</h1>
              <HeaderP className="font13 semiBold" style={{textAlign: "justify"}}>
                Flexcoord workflow automation system enable organization streamlines and automates repetitive tasks and business processes, enhancing 
                efficiency and productivity across an organization. It offers features such as visual process design, automated tasks, conditional logic, notifications, 
                document routing, and real-time monitoring. The system improves accuracy, increases productivity, and ensures compliance with built-in controls and 
                audit trails. By automating workflows, teams can focus on higher-value tasks, while the system handles routine operations smoothly.
              </HeaderP>
            </div>
          </LeftSide>
          <RightSide>
            <ImageWrapper>
              <Img className="radius8" src={Sol3} alt="office" style={{zIndex: 9}} />
              <DotsWrapper>
                <Dots />
              </DotsWrapper>
            </ImageWrapper>
          </RightSide>
        </Wrapper>
      </Legend>

      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide>
          <ImageWrapperLegend>
            <Img className="radius8" src={Sol4} alt="office" style={{zIndex: 9}} />
            <DotsWrapperLegend>
              <Dots />
            </DotsWrapperLegend>
          </ImageWrapperLegend>
        </LeftSide>
        <RightSide className="flexCenter">
          <div>
            <h1 className="extraBold font40">Electronic Document Management System (EDMS)</h1>
            <HeaderP className="font13 semiBold" style={{textAlign: "justify"}}>
              Flexcoord electronic document management system (EDMS) helps organization organizes, stores, and retrieves documents digitally, offering a centralized 
              repository for easy access and management. Key features include centralized storage, version control, access control, advanced search and retrieval, and collaboration tools, 
              EDMS enhances accessibility, collaboration, and efficiency while strengthening compliance with audit trails and access permissions. It streamlines document 
              processes, reduces paper usage and storage costs, and provides security through data encryption and backups, leading to smoother operations and improved document management within organizations.
            </HeaderP>
          </div>
        </RightSide>
      </Wrapper>
    </>
  );
}

const WrapperLegend = styled.section`
  padding-top: 150px;
  padding-bottom: 120px;
  width: 100%;
`;
const Legend = styled.div`
  width: 100%;
`;
const Wrapper = styled.section`
  padding-top: 40px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const ImageWrapperLegend = styled.div`
  display: flex;
  justify-content: flex-center;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const DotsWrapperLegend = styled.div`
  position: absolute;
  right: 80px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;