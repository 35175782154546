import React from "react";
// Sections
import Footer from "../components/Sections/Footer"
import Contact from "../components/Sections/Contact/Contact"

export default function Contacts() {
  return (
    <>
      <Contact />
      <Footer />
    </>
  );
}