import React from "react";
import styled from "styled-components";
// Assets
import ContactImg from "../../../assets/img/contact.png";
import Dots from "../../../assets/svg/Dots";
import {useState} from 'react';
import axios from "axios"

export default function Contact() {
  const [formData, setFormData] = useState({fname: "", email: "", company: "", message: ""});

  const [inputError, setInputError] = useState(null);
  const [inputSuccess, setInputSuccess] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleReset = () =>{
    // reset({});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setInputSuccess(null)
    setInputError(null)
    if (formData.fname === "" || formData.email === "" || formData.company === "" || formData.message === "" ) {
      setInputError('Fill all fields');
    } else {
      axios.post('https://apiv1.flexcoord.com/api/v1/contact_us', formData, 
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        setInputError(null)
        setInputSuccess(null)
        console.log(response.status)
        if(response.status === 200){
          setInputSuccess("Your request has been successfully submitted");
          handleReset();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  };

  return (
    <Wrapper id="contact">
      <TopBanner className="lightBg textCenter">
          <h1 className="font30 extraBold" style={{paddingTop: "85px"}}>Looking for the solution that best fits your business <br />
          needs? Fill out the contact form and <br /> our Sales team will be in touch</h1>
      </TopBanner>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13 semiBold">
              Send us a message!
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form id="sumForm">
                {inputError && <div style={{ color: 'red', paddingBottom: '20px'}}>{inputError}</div>}
                {inputSuccess && <div style={{ color: 'green', paddingBottom: '20px'}}>{inputSuccess}</div>}
                <label className="font13">Full Name:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold"  value={formData.fname}required onChange={handleChange}/>
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" value={formData.email} required onChange={handleChange}/>
                <label className="font13">Company Name:</label>
                <input type="text" id="company" name="company" className="font20 extraBold" value={formData.company} required onChange={handleChange}/>
                <label className="font13">Write your message here:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" value={formData.message} required onChange={handleChange}/>
                <button hidden type="button" onClick={handleReset}>Reset</button>
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} onClick={handleSubmit}/>
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <ImageWrapper>
                <Img className="radius8" src={ContactImg} alt="office" style={{zIndex: 9}} />
                <DotsWrapper>
                  <Dots />
                </DotsWrapper>
              </ImageWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const TopBanner = styled.div`
  width: 100%;
  min-height: 300px;
`;
const Wrapper = styled.section`
  width: 100%;
  Padding-top: 80px
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #f2b300;
  background-color: #f2b300;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









