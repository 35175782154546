import React from "react";
import styled from "styled-components";
// Assets
import About from "../../../assets/img/about.png";
import Dots from "../../../assets/svg/Dots";

export default function Header() {
  return (
    <>
        <Wrapper id="home" className="container flexSpaceCenter">
            <LeftSide className="flexCenter">
                <div>
                    <h5>About</h5>
                    <h1 className="extraBold font30">Project management, Task management, Workflow automation and Document storage made easy</h1>
                    <HeaderP className="font13 semiBold" style={{textAlign: "justify"}}>
                        Flexcoord is a comprehensive SaaS solution that optimizes project and task management for organizations. It offers project planning and 
                        task assignment, real-time progress tracking, and centralized document storage. With automated file routing workflows, it streamlines document 
                        handling and approvals, enhancing efficiency and reducing errors. Flexcoord supports seamless team collaboration and transparent stakeholder 
                        involvement, with advanced search and role-based access control for secure and quick document access. By improving communication, productivity, and 
                        accountability, Flexcoord helps teams achieve project goals more effectively.
                    </HeaderP>
                </div>
            </LeftSide>
            <RightSide>
                <ImageWrapper>
                    <Img className="radius8" src={About} alt="office" style={{zIndex: 9}} />
                    <DotsWrapper>
                        <Dots />
                    </DotsWrapper>
                </ImageWrapper>
                <GreyDiv className="lightBg"></GreyDiv>
            </RightSide>
        </Wrapper>

        <WrapperIn>
            <div className="textCenter">
                <p className="extraBold font30">Good software is a work of art, and good art takes time. Our teams spend years 
                <br />mastering their craft to deliver exceptional products 
                <br />that customers love.</p>
            </div>
        </WrapperIn>

        <WrapperOut className="container flexSpaceCenter">
            <OutContainer>
                <div>
                    <h3 className="extraBold font30">Our Mission</h3>
                    <p className="font13">We believe that every organization has a higher potential to make more profit when provided with the 
                        right technological solution to get more work done within a short period; And we are on a mission to provide that one platform to 
                        Organizations that will automate thier business processes and improve their profitability.</p>
                </div>
            </OutContainer>

            <OutContainer>
                <div>
                    <h3 className="extraBold font30">Our Vision</h3>
                    <p className="font13 semiBold">To become Africa’s leading software as a service (SAAS) company that provide company(s), with the right 
                        technological solutions to get more work down within a short period of time by automating their business processes at an affordable price by 2027.</p>
                </div>
            </OutContainer>
        </WrapperOut>
    </>
  );
}

const WrapperIn = styled.section`
    padding-top: 80px;
    width: 100%;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;
const WrapperOut = styled.section`
    padding-top: 80px;
    width: 100%;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;
const OutContainer = styled.div`
    width: 50%
    text-align: center;
    background: #002366;
    margin: 50px;
    color: #fff; 
    border-radius: 10px;
    padding: 30px;
    @media (max-width: 960px) {
        width: 100%;
    }
`
const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
     margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;