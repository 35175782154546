import React from "react";
// Sections
import Footer from "../components/Sections/Footer";
import Blog from "../components/Sections/Resource/Blog"

export default function Resources() {
  return (
    <>
      <Blog />
      <Footer />
    </>
  );
}