import React from "react";
// Sections
import Footer from "../components/Sections/Footer"
import Pricing from "../components/Sections/Pricing/Pricing"

export default function Pricings() {
  return (
    <>
      <Pricing />
      <Footer />
    </>
  );
}