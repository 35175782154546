import React from "react";
// Sections
import Solutions from "../components/Sections/Solution/Solutions";
import Footer from "../components/Sections/Footer"

export default function Solution() {
  return (
    <>
      <Solutions />
      <Footer />
    </>
  );
}