import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div>
        <Slider {...settings}>
            <div className="textCenter">
                <h3>"Enhances operational efficiency, improved collaboration <br/> and instant document retrieval"</h3>
            </div>
        </Slider>
    </div>
  );
}